import {connect} from 'react-redux';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {reducer as formReducer} from 'redux-form';
import {Helmet} from 'react-helmet-async';

import {ClientSettingsContext} from 'web/hooks/useClientSettings';
import MinimalLayout from 'web/components/minimal_layout';
import SubmitButton from 'web/components/submit_button';
import {DisplayTotals} from 'web/components/totals';
import {formatCents} from 'web/helpers/money';

import SanitizationChanges from '../basket_page/components/sanitization_changes';
import MasqueradeOverrides from '../basket_page/components/masquerade_overrides';
import * as basketPageDuck from '../basket_page/duck';
import CheckoutErrors from '../components/checkout_errors';
import StepsHeader from '../components/steps_header';
import FulfillmentDetails from './components/fulfillment_details';
import ItemsSummary from './components/items_summary';
import PaymentDetails from './components/payment_details';
import * as reviewPageDuck from './duck';
import {actions} from '../payment_details_page/duck';
import TippingSection from './components/tipping_section';
import {TippingContextProvider} from './components/tipping_section/tipping_context';
import {FeatureFlags} from '../../helpers/experiments/feature_flags';

export class ReviewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockedCheckout: true,
      shakeTippingSection: false,
    };
    this.handleTipSelectionCallback = this.handleTipSelectionCallback.bind(this);
  }

  handleTipSelectionCallback(isTipFeeSet) {
    this.setState({blockedCheckout: !isTipFeeSet});
  }

  componentDidUpdate() {
    if (this.state.shakeTippingSection === true) {
      setTimeout(
        function () {
          this.setState({shakeTippingSection: false});
        }.bind(this),
        3000,
      );
    }
  }

  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  static reducer(state, action) {
    return update(state, {
      totals: {
        $set: reviewPageDuck.reducer(state.totals, action),
      },
      reviewPage: {
        $set: reviewPageDuck.reducer(state.reviewPage, action),
      },
      form: {
        $set: formReducer(state.form, action), // Form reducer for masquerade overrides
      },
    });
  }

  render() {
    const clientSettings = this.context;
    const checkoutLabel = this.props.isAddingToOrder ? 'Add To Order' : 'Place Order';
    const isUserAllowedForTipping =
      !this.props.isAddingToOrder && this.props.features?.includes(FeatureFlags.TippingWeb);
    return (
      <TippingContextProvider
        displayTotals={this.props.totals.displayTotals}
        onChange={(tipinCents, tipPercentage) => {
          this.props.handleTip(tipinCents, tipPercentage);
        }}
      >
        <MinimalLayout>
          <Helmet>
            <title>Review Order | Good Eggs</title>
            {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
            <html className="with-fixed-minimal-footer hide-minimal-fixed-footer-mobile" />
            <body className="review_order_page" />
          </Helmet>

          <StepsHeader currentStep={3} />
          <div className="review-order-view narrow-page gutter">
            <div className="order">
              <div className="title">
                <h1 className="checkout__title">Review &amp; place your order</h1>
                {this.props.reviewPage.error ? (
                  <CheckoutErrors {...this.props.reviewPage.error} />
                ) : null}
                <SanitizationChanges sanitizationChanges={this.props.sanitizationChanges} />
                <hr className="checkout__title-hr" />
                <div className="below-title">
                  <div className="everything-looks-good">Everything look good?</div>
                  <SubmitButton
                    className="place-order button button--checkout"
                    isSaving={this.props.reviewPage.working}
                    onClick={() => {
                      this.setState({shakeTippingSection: true});
                      this.props.handleCheckout(this.props.user, this.state.blockedCheckout);
                    }}
                    workingLabel="Processing"
                    disabled={this.props.disableCheckout}
                  >
                    {checkoutLabel}
                  </SubmitButton>
                </div>
              </div>
              {this.props.user.masquerading ? (
                <MasqueradeOverrides handleSubmit={this.props.handleSaveMasquerradeOverrides} />
              ) : null}
              <FulfillmentDetails
                fulfillmentOffer={this.props.fulfillmentOffer}
                deliveryDetails={this.props.deliveryDetails}
                tzid={clientSettings.tzid}
                isAttendedDeliveryRequired={this.props.isAttendedDeliveryRequired}
              />
              <PaymentDetails
                promoCodes={this.props.promoCodes}
                referralGift={this.props.basket.referralGift}
                creditCard={this.props.user.active_card}
                availableStoreCredit={this.props.user.availableBalance}
                allowChange={!this.props.isAddingToOrder}
                basketContainsPromoProducts={this.props.hasPromoProducts}
                onRemovePromoCodes={this.props.dismissPromoCodes}
              />
              <div className="order-summary">
                <div className="raised-tile__header">
                  {this.props.isAddingToOrder ? 'Adding To Your Delivery' : 'Order Summary'}
                </div>
                <div className="raised-tile__body">
                  <ItemsSummary
                    items={this.props.basket.items}
                    productsById={this.props.uiProductsById}
                    lineItemTotals={this.props.totals.lineItemTotals}
                  />
                </div>
              </div>
              {clientSettings.enableTipping && isUserAllowedForTipping && (
                <TippingSection
                  handleTipSelectionCallback={this.handleTipSelectionCallback}
                  shakeTippingSection={this.state.blockedCheckout && this.state.shakeTippingSection}
                />
              )}
              <DisplayTotals
                displayTotals={this.props.totals.displayTotals}
                showDeliveryFee
                isMembershipOrder={this.props.isMembershipOrder}
                totalSavings={this?.props.basket?.totals?.totalSavings}
              />
              <div className="desktop-checkout-panel">
                <SubmitButton
                  isSaving={this.props.reviewPage.working}
                  onClick={() => {
                    this.setState({shakeTippingSection: true});
                    this.props.handleCheckout(this.props.user, this.state.blockedCheckout);
                  }}
                  className="place-order button button--checkout"
                  workingLabel="Processing"
                  disabled={this.props.disableCheckout}
                >
                  {checkoutLabel}
                </SubmitButton>
              </div>
            </div>
            <div className="fixed-checkout-panel">
              <div className="receipt totals">
                <div className="totals__total-row">
                  <span className="totals__total-label">Total</span>
                  <div className="totals__total-formatted-amount">
                    {formatCents(
                      this.props.isAddingToOrder
                        ? this.props.totals.subtotal
                        : this.props.totals.totalDue,
                    )}
                  </div>
                </div>
              </div>
              <SubmitButton
                isSaving={this.props.reviewPage.working}
                onClick={() => {
                  this.setState({shakeTippingSection: true});
                  this.props.handleCheckout(this.props.user, this.state.blockedCheckout);
                }}
                className="place-order button button--checkout"
                workingLabel="Processing"
                disabled={this.props.disableCheckout}
              >
                {checkoutLabel}
              </SubmitButton>
            </div>
          </div>
        </MinimalLayout>
      </TippingContextProvider>
    );
  }
}

ReviewPage.propTypes = {
  currentFulfillmentDay: PropTypes.string,
  deliveryDetails: PropTypes.object,
  fulfillmentOffer: PropTypes.object,
  isAddingToOrder: PropTypes.bool,
  isMembershipOrder: PropTypes.bool,
  basket: PropTypes.object,
  sanitizationChanges: PropTypes.array,
  user: PropTypes.object,
  uiProductsById: PropTypes.object,
  masqueradeOverrides: PropTypes.object,
  totals: PropTypes.object,
  promoCodes: PropTypes.array,
  isAttendedDeliveryRequired: PropTypes.bool,
  disableCheckout: PropTypes.bool,
  handleSaveMasquerradeOverrides: PropTypes.func,
  handleCheckout: PropTypes.func,
  dismissPromoCodes: PropTypes.func,
  reviewPage: PropTypes.shape({
    working: PropTypes.bool,
    error: PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
    }),
  }),
};

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    handleSaveMasquerradeOverrides: (masqueradeOverrides) => {
      const {ignoreMinimumOrder, dontSendConfirmation, ignoreCapacities} = masqueradeOverrides;
      dispatch(
        basketPageDuck.actions.updateMasqueradeOverrides(
          ignoreMinimumOrder,
          dontSendConfirmation,
          ignoreCapacities,
        ),
      );
    },
    handleTip: (tipCents, tipPercentage) => {
      dispatch(reviewPageDuck.actions.setTip(tipCents, tipPercentage));
    },
    handleCheckout: (user, blockedCheckout) => {
      if (blockedCheckout) return;
      dispatch(reviewPageDuck.actions.checkout(user));
    },
    dismissPromoCodes: () => dispatch(actions.dismissPromoCodes()(dispatch)),
  };
}

ReviewPage.pageName = 'Review Order';

const ConnectedReviewPage = connect(mapStateToProps, mapDispatchToProps)(ReviewPage);

export default ConnectedReviewPage;
